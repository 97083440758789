import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import DataPacket from '../types/DataPacket';

class Receiver extends Component {
  state = {
    result: {},
  };

  handleScan = data => {
    if (data) {
      let parsed = DataPacket.fromJson(data);
      console.log("Got data: " + data)
      if (!parsed) {
        parsed = DataPacket.fromRaw(data);
      }
      console.log("\tType: " + parsed.type)
      this.setState({
        result: parsed,
      });
    }
  };

  handleError = err => {
    console.log("Got error :(")
    console.error(err);
  };

  showText() {
    return (
      <textarea
        readOnly={true}
        value={this.state.result.data}
        rows={4}
        onChange={this.handleChange}
        style={{ width: '100%', fontSize: '1.5em' }}
      />
    );
  }

  showFile() {
    let result = this.state.result;
    let file = new Blob([result.data], { type: result.fileType });
    let content = URL.createObjectURL(file);
    return (
      <a
        href={content}
        download={result.name}
        style={{ width: '100%', fontSize: '1.5em' }}
      >{result.name}</a>
    );
  }

  showURL() {
    return (
      <a
        href={this.state.result.data}
        style={{ width: '100%', fontSize: '1.5em' }}
      >{this.state.result.data}</a>
    );
  }

  showResult() {
    if (this.state.result.type === 'url') {
      return this.showURL();
    } else if (this.state.result.type === 'file') {
      return this.showFile();
    }
    return this.showText();
  }

  render() {
    return (
      <div style={{}}>
        <div
          style={{
            width: '95vmin',
            margin: 'auto',
          }}
        >
          <QrReader
            resolution={640}
            delay={0}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
          />
          {this.showResult()}


        </div>
      </div>
    );
  }
}

export default Receiver;
