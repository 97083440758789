import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import './App.css';
import TextSender from './sender/TextSender';
import Receiver from './receiver/Receiver';
import FileSender from './sender/FileSender';

function App() {
  return (
    <div
      className="App"
      style={{}}
    >
    <Tabs>
      <TabList>
        <Tab>Text</Tab>
        <Tab>Files</Tab>
        <Tab>Receiver</Tab>
      </TabList>

      <TabPanel>
        <TextSender />
      </TabPanel>
      <TabPanel>
        <FileSender />
      </TabPanel>
      <TabPanel>
        <Receiver />
      </TabPanel>
    </Tabs>
    </div>
  );
}

export default App;
