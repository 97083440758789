import React from 'react';
import QRCode from 'react-qr-code';
import DataPacket from '../types/DataPacket';

class TextSender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      type: 'text',
      result: {},
    };

    this.valueChange = this.valueChange.bind(this);
    this.typeChange = this.typeChange.bind(this);
  }

  valueChange(event) {
    this.setState({ value: event.target.value }, () => {
      this.updateResult();
    });
  }

  typeChange(event) {
    this.setState({ type: event.target.value }, () => {
      this.updateResult();
    });
  }

  updateResult() {
    let data = new DataPacket(this.state.type, this.state.value).toJson();
    console.log('New state: ' + data);
    this.setState({ result: data });
  }

  render() {
    return (
      <div style={{}}>
        <div
          className="Sender"
          style={{ width: 384, margin: 'auto' }}
        >
          <QRCode
            value={String(this.state.result)}
            size={384}
          />
          <textarea
            name="dataValue"
            value={this.state.value}
            rows={4}
            onChange={this.valueChange}
            style={{ width: '100%', fontSize: '1.5em' }}
          />
          <select
            name="dataType"
            onChange={this.typeChange}
          >
            <option value="text"> Text</option>
            <option value="url"> URL</option>
          </select>
        </div>
      </div>
    );
  }

}

export default TextSender;
