class SingleFilePacket {
  constructor(data, name, fileType) {
    this.type = 'file';
    this.data = data;
    this.name = name;
    this.fileType = fileType;
  }

  toJson() {
    let encoded = new Buffer(this.data).toString('base64');
    let temp = { type: this.type, data: encoded, name: this.name, fileType: this.fileType };

    return JSON.stringify(temp);
  }

  static fromJson(string) {
    try {
      let parsed = JSON.parse(string);
      let decoded = Buffer.from(parsed.data, 'base64').toString();

      return new SingleFilePacket(decoded, parsed.name, parsed.fileType);
    } catch (e) {
    }
    return null;
  }
}

export default SingleFilePacket;
