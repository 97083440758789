import SingleFilePacket from './SingleFilePacket';

class DataPacket {
  constructor(type, data) {
    this.type = type;
    this.data = data;
  }

  toJson() {
    let encoded = new Buffer(this.data).toString('base64');
    let temp = { type: this.type, data: encoded };

    return JSON.stringify(temp);
  }

  static fromJson(string) {
    try {
      let parsed = JSON.parse(string);
      if (parsed.type === 'file') {
        return SingleFilePacket.fromJson(string);
      }

      let decoded = Buffer.from(parsed.data, 'base64').toString();
      return new DataPacket(parsed.type, decoded);
    } catch (e) {
      console.log("Error parsing string:" + e)
    }
    return null;
  }

  static fromRaw(string) {
    return new DataPacket('text', string);
  }
}

export default DataPacket;
