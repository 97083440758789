import React from 'react';
import QRCode from 'react-qr-code';
import SingleFilePacket from '../types/SingleFilePacket';

class FileSender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: {},
    };

    this.valueChange = this.valueChange.bind(this);
  }

  valueChange(event) {
    if (!event.target.files || event.target.files.length < 1) {
      return;
    }

    let file = event.target.files[0];
    file.text().then(content => {
      let packet = new SingleFilePacket(content, file.name, file.type).toJson()
      console.log('New data: ' + packet);
      this.setState({ result: packet });

    });
  }

  render() {
    return (
      <div style={{}}>
        <div
          className="Sender"
          style={{ width: 384, margin: 'auto' }}
        >
          <QRCode
            value={String(this.state.result)}
            size={384}
          />
          <input
            type="file"
            name="file"
            onChange={this.valueChange}
          /></div>
      </div>
    );
  }
}

export default FileSender;
